/* eslint-disable max-classes-per-file */
type ConfigEntry = {
  characteristic: string;
  value: string;
}

type Connection = {
  sap: string;
  type: string;
  diameter: number;
  description: string;
}

export type Text = {
  language: string | null;
  textId: string | null;
  textValue: string | null;
}

export class LineItem {
  sapId!: number | null;

  quantity!: number | null;

  salesUnit!: string | null;

  overrideName!: string | null;

  netWeightOverride!: number | null;

  grossWeightOverride!: number | null;

  watchUnitOverride!: string | null;

  volumeOverride!: number | null;

  volumeUnitOverride!: string | null;

  config!: ConfigEntry[];

  edgeConnectionsForCalc!: Connection[];

  texts!: Text[];

  customerMaterialNumber!: string | null;
}

export class ExtendedLineItem extends LineItem {
    totalPrice!: number | null;

    currency!: string | null;

    appComments!: string | null;

    static fromLineItem(lineItem: LineItem): ExtendedLineItem {
      const clone: ExtendedLineItem = Object.assign(new ExtendedLineItem(), lineItem);

      clone.setComments();
      return clone;
    }

    setComments(): void {
      const notes: string[] = [];

      if (this.config) {
        notes.push('with config.');
      }

      if (this.edgeConnectionsForCalc) {
        notes.push('service items missing');
      }

      if (this.overrideName) {
        notes.push('descr. override');
      }

      if (this.volumeUnitOverride) {
        notes.push('weight & volume override');
      }

      if (notes.length > 0) {
        this.appComments = notes.join(', ');
      } else {
        this.appComments = '';
      }
    }
}
